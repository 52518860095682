import Titulo from "../Titulo"

const Premiacoes = () => {
    return (
        <section>
        <Titulo texto="Premiações" />
        <section class="mb-8">


            <article itemscope itemtype="http://schema.org/EducationalOccupationalCredential" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-lg font-bold mb-2">Título de Reconhecimento pela International School of Administration and Business (ISAB)</h2>
                <p itemprop="address" content="Miami, EUA" class="mb-2">MIAMI / EUA 2022</p>
                <div itemprop="educationalLevel" content="Recognition" class="mb-2">Reconhecimento</div>
                <div itemprop="awardingOrganization" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">International School of Administration and Business (ISAB)</span>
                    <meta itemprop="address" content="Miami, EUA" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/Person" class="mb-4 ml-2">
                <h2 class="text-lg font-bold mb-2">Jornalista Premiada (Premiação Personalidade Revelação)</h2>
                <p itemprop="address" content="Minas Gerais, Brasil" class="mb-2">Minas Gerais / Brasil 2015</p>
                <div itemprop="honorificPrefix" class="mb-2">Jornalista Premiada</div>
                <div itemprop="description" class="mb-2">Premiação Personalidade Revelação</div>
                <div itemprop="locationCreated" itemscope itemtype="http://schema.org/AdministrativeArea">
                    <meta itemprop="name" content="Minas Gerais" />
                    <div itemprop="address" itemscope itemtype="http://schema.org/Country">
                        <meta itemprop="name" content="Brasil" />
                    </div>
                </div>
            </article>
        </section>
    </section>
    )
}

export default Premiacoes