import React from 'react';
import Sobre from '../Sobre';
import BarraLateral from '../BarraLateral';
import Referencias from '../Referencias';
import Formacoes from '../Formações';
import Premiacoes from '../Premiações';



function App() {
    return (
    <div className="flex flex-col-reverse xl:flex-row justify-around">
       
        <section className="w-full  xl:w-8/12 bg-gray-200 p-8">
                <div className="flex flex-col justify-between overflow-x-hidden">
                    <Sobre />
                    <Formacoes />
                    <Premiacoes/>
                </div>
            </section>
            <BarraLateral />

    </div>

    );
}

export default App;
