import Titulo from "../Titulo"

const Sobre = () => {
    return (
        <section className="flex flex-col gap-5">
            <article itemscope itemtype="http://schema.org/Article">
                <Titulo texto="Jennifer de Paula" itemprop="name" />
                <div>
                    <p itemprop="description">
                        Descomplicar o marketing digital é um dos objetivos da jornalista e administradora Jennifer de Paula. A carioca é residente de Portugal, e hoje, está à frente da diretoria da MF Press Global, empresa de marketing, comunicação e assessoria de imprensa. Além de ser a responsável por gerir marcas, empresas e clientes de várias partes do mundo.
                    </p>
                    <p itemprop="description">
                        Com mais de 8 anos de experiência na área, Jennifer de Paula garante que todo mundo tem potencial para crescer na internet, desde que tenha um plano estratégico bem elaborado e saiba usar as ferramentas corretas para criar e consolidar a imagem em meio ao universo das redes. Entre o crescimento rápido e fugaz, e a construção lenta, porém estrutura da imagem, a especialista fica com a segunda opção e, a cada dia, acumula mais uma personalidade de sucesso ao rol de clientes auxiliados pelo seu trabalho.
                    </p>
                </div>
            </article>

            <span className="font-light italic text-center" itemscope itemtype="http://schema.org/CreativeWork">
                "Desmistificar o marketing digital e ajudar a impulsionar o perfil e imagem de um jeito certo, eficaz e duradouro."
            </span>
        </section>
    )
}


export default Sobre