import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './components/Home';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <main className="container mx-auto p-4 xl:py-16">
    
    <Home />
    </main>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a functio
