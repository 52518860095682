import React from 'react';

const BarraLateral = () => {
  return (
<aside className='bg-gray-200 p-3 mb-3 xl:w-1/4 h-fit'>
  <table className="w-full" itemScope itemType="http://schema.org/Person">
    <thead>
      <tr>
        <th className='text-center bg-gray-300 text-lg' colSpan="2" itemProp="name">
          <span>Jennifer de Paula</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan="2" className="text-center">
          <img src="/jenniferdepaula.png" alt="Jennifer de Paula" className="mx-auto my-4 w-64 h-auto" />
        </td>
      </tr>
      <tr>
        <th className='text-center bg-gray-300 text-lg' colSpan="2">
          <span>Informações Gerais</span>
        </th>
      </tr>
      <tr>
        <td className="font-semibold">Nome completo</td>
        <td itemProp="name">Jennifer Aline Silva de Paula</td>
      </tr>
      <tr>
        <td className="font-semibold">Nascimento</td>
        <td>
          <time itemProp="birthDate" dateTime="1992-10-23"> 23 de outubro de 1992 </time>
        </td>
      </tr>
     
      <tr>
        <td className='font-semibold'>Cônjuge</td>
        <td>
        <span itemProp="spouse" itemScope itemType="http://schema.org/Person">
          <span itemProp="name"><a className='text-blue-500 hover:text-blue-400' href="https://www.google.com/search?kgmid=/g/11h2097ztt&hl=pt-PT&q=Fabiano+de+Abreu&kgs=fa8c474886305ce9&shndl=17&source=sh/x/kp/osrp/1" target="_blank" rel='noreferrer'>Fabiano de Abreu</a>
          </span></span>
        </td>
      </tr>
      <tr>
        <td className="font-semibold">Nacionalidade</td>
        <td itemProp="nationality">Brasileira</td>
      </tr>
      <tr>
        <td className="font-semibold">Local de nascimento</td>
        <td itemProp="birthPlace">Rio de Janeiro</td>
      </tr>
      <tr>
        <td className="font-semibold">Moradia Atual</td>
        <td itemProp="address">Castelo de Paiva - Portugal</td>
      </tr>
      <tr>
        <td className="font-semibold">Cargo</td>
        <td itemProp="jobTitle">Sócia–Diretora da MF Press Global</td>
      </tr>
      <tr>
        <td className="font-semibold">Empresa</td>
        <td itemProp="worksFor"> <a href="https://pressmf.global/" className='text-blue-500 hover:text-blue-400'>MF Press Global</a></td>
      </tr>
      <tr>
        <td className="font-semibold">Livro(s) Publicados</td>
        <td itemProp="creativeWork"> <a href="https://www.amazon.com/dp/B0C55MVJSK" className='text-blue-500 hover:text-blue-400'>100 dicas para transformar o óbvio em ação</a></td>
      </tr>
      <tr>
        <td className="font-semibold">Redes Sociais</td>
        <td>
          <span itemProp="sameAs" itemScope><a href="https://www.instagram.com/ajenniferdepaula/" target="_blank" rel='noreferrer' className='text-blue-500 hover:text-blue-400'>Instagram
          </a></span><br />          
          <span itemProp="sameAs" itemScope><a href="https://www.facebook.com/gestaojenniferdepaula" target="_blank" rel='noreferrer' className='text-blue-500 hover:text-blue-400'>Facebook
          </a></span><br />          
          <span itemProp="sameAs" itemScope><a href="https://www.linkedin.com/in/jennifer-de-paula-3a2436199" target="_blank" rel='noreferrer' className='text-blue-500 hover:text-blue-400'>LinkedIn
          </a></span><br />          
          <span itemProp="sameAs" itemScope><a href="https://jenniferdepaula.com/" target="_blank" rel='noreferrer' className='text-blue-500 hover:text-blue-400'>Site Oficial
          </a></span><br />     
          <span itemProp='sameAs' itemScope><a target="_blank" rel='noreferrer' className='text-blue-500 hover:text-blue-400' href='https://www.google.com.br/search?kgmid=/g/11stwp6qh4'>Painel de informações do Google</a></span>
             
        </td>
      </tr>
     
    </tbody>
  </table>
</aside>


  );
};

export default BarraLateral;

