import Titulo from "../Titulo"

const Formacoes = () => {
    return (

        <section className="my-5">
            <Titulo texto="Formações"  />
            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Jornalismo – FENAJ</h2>
                <p>Federação Nacional dos Jornalistas</p>                
            </article>
            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Jornalismo Internacional</h2>
                <p>Card number: BR16790</p>                
            </article>


            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Pós-Graduação “Lato Sensu” em MBA em Marketing Digital e Negócios Interativos</h2>
                <p>SÃO PAULO 2022</p>
                <ul class="list-disc pl-8">
                    <li>Comércio Eletrônico</li>
                    <li>Empreendedorismo</li>
                    <li>Estratégia de Marketing Digital</li>
                    <li>Inteligência em Redes Sociais</li>
                    <li>Liderança e Motivação de Equipes</li>
                    <li>Marketing</li>
                    <li>Marketing Viral e Mídia Online</li>
                    <li>Orientação e Trabalho de Conclusão de Curso (TCC)</li>
                    <li>Práticas de Gestão Estratégica e Marketing</li>
                </ul>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">FAI (Faculdade Interativa de São Paulo)</span>
                    <meta itemprop="address" content="São Paulo, Brasil" />
                    <meta itemprop="educationalCredentialAwarded" content="MBA" />
                    <meta itemprop="hasCredential" content="BR16790" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Bachelor of Business Administration (BBA) Major in Marketing - UNILOGOS</h2>
                <p itemprop="address" content="Miami, EUA" class="mb-2">MIAMI - 2021</p>
                <ul class="list-disc pl-8">
                    <li>Business Communication</li>
                    <li>Managing Business</li>
                    <li>Accounting Principles I</li>
                    <li>E-Commerce</li>
                    <li>Principles of Economics</li>
                    <li>Principles of Marketing</li>
                    <li>Human Resource Management</li>
                    <li>Accounting Principles II</li>
                    <li>Operations Management</li>
                    <li>Entrepreneurship</li>
                    <li>Sustainable Business Practices</li>
                    <li>Financial Reporting</li>
                    <li>Logistics and Supply Chain Management</li>
                    <li>Cross-Cultural Marketing</li>
                    <li>Business Information Management</li>
                    <li>Marketing Image Branding and Recognition</li>
                    <li>Social Media Management</li>
                    <li>Integrated Marketing Communication</li>
                    <li>Digital Marketing</li>
                    <li>Consumer Behaviour Outline</li>
                    <li>Sales Management</li>
                    <li>Marketing Research & Analysis</li>
                    <li>Integrated Marketing Communications Capstone Project</li>
                </ul>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">UNILOGOS (Logos University International)</span>
                    <meta itemprop="address" content="Miami, EUA" />
                    <meta itemprop="educationalCredentialAwarded" content="BBA" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Profissional Web Marketeer – Traininghouse</h2>
                <p itemprop="address" content="Faro, Portugal" class="mb-2">FARO - PORTUGAL 2020</p>
                <ul class="list-disc pl-8">
                    <li>Comércio Eletrônico e Sistemas de Informação</li>
                    <li>Plataformas Digitais</li>
                    <li>Social Media Marketing</li>
                    <li>E-mail Marketing</li>
                    <li>Search Engine Marketing</li>
                </ul>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">Traininghouse</span>
                    <meta itemprop="address" content="Faro, Portugal" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Formação Avançada em Nutrição Clínica – Traininghouse</h2>
                <p itemprop="address" content="Faro, Portugal" class="mb-2">FARO - PORTUGAL 2020</p>
                <ul class="list-disc pl-8">
                    <li>Alimentação e Nutrição</li>
                    <li>Necessidades Nutricionais no Ciclo de Vida</li>
                    <li>Nutrição Entérica e Parentérica</li>
                    <li>Interação Medicamento-Alimentos</li>
                    <li>Avaliação Nutricional</li>
                    <li>Nutrição na Doença</li>
                    <li>Nutrição Oncológica</li>
                </ul>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">Traininghouse</span>
                    <meta itemprop="address" content="Faro, Portugal" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Curso Aprender para Liderar - PUCS</h2>
                <p itemprop="address" content="Porto Alegre, Brasil" class="mb-2">PORTO ALEGRE - BRASIL 2022</p>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">Pontifícia Universidade Católica do Rio Grande do Sul</span>
                    <meta itemprop="address" content="Porto Alegre, Brasil" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Segurança do Trabalho – Traininghouse</h2>
                <p itemprop="address" content="Faro, Portugal" class="mb-2">FARO PORTUGAL 2021</p>
                <ul class="list-disc pl-8">
                    <li>Acolhimento e Apresentação do Curso</li>
                    <li>Enquadramento Legal</li>
                    <li>Conceitos de Segurança no Trabalho</li>
                    <li>Boas Práticas de Segurança no Trabalho</li>
                </ul>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">Traininghouse</span>
                    <meta itemprop="address" content="Faro, Portugal" />
                </div>
            </article>

            <article itemscope itemtype="http://schema.org/EducationalOrganization" class="mb-4 ml-2">
                <h2 itemprop="name" class="text-base md:text-lg font-bold mb-2">Técnicas de Administração – SEPE</h2>
                <p itemprop="address" content="Rio de Janeiro, Brasil" class="mb-2">RIO DE JANEIRO 2007</p>
                <div itemprop="alumniOf" itemscope itemtype="http://schema.org/EducationalOrganization">
                    <span itemprop="name">SEPE</span>
                    <meta itemprop="address" content="Rio de Janeiro, Brasil" />
                </div>
            </article>
        </section>


    )
}

export default Formacoes